import React from "react"
import classNames from "classnames"
import './nav-icon.scss'

class NavIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animation: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active && !this.state.animation) {
      this.setState({animation:true})
    }
  }

  render() {
    return (
      <div className="_as">
          <div className={classNames("_l", {"_p": this.props.active})}>
            <div className={classNames("_m", {"_k": !this.state.animation})}></div>
            <div className={classNames("_n", {"_k": !this.state.animation})}></div>
            <div className={classNames("_o", {"_k": !this.state.animation})}></div>
          </div>
      </div>
    )
  }
}

export default NavIcon;
