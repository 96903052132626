import React from "react"
import PropTypes from "prop-types";
import classNames from 'classnames'

import './class-overview.sass'

const ClassOverview = ({children, text, title, multiColumn}) => (
  <section className={classNames('_b7', {'_9p': multiColumn})}>
    <div className="_13">
      <div className={'_15'}>
      <h3 className={'_4d _9n'}>{title}</h3>
      {text &&
        <p className={'_9o _4j'}>{text}</p>
      }

      <div className={'_9q'}>
        {children}
      </div>
    </div>
    </div>
  </section>
)

ClassOverview.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
  multiColumn: PropTypes.bool
}

ClassOverview.defaultProps = {
  title: 'Übersicht',
  multiColumn: false
}

export default ClassOverview
