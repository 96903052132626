import React from "react"
import { Slider, CircularSlideStyle, LinearSlideStyleWithScale } from "@fp/react-slider"
import ArrowLeft from "../../images/arrow_left.svg"
import ArrowRight from "../../images/arrow_right.svg"

import "./classes-slider.sass"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

class ClassesSlider extends React.Component {


  render() {
    const circular = new LinearSlideStyleWithScale([.8, .93, 1, .93, .8])


    return <section className={"_6q _13"}>

      <h2 className={"_14 _4d _6r"}>Einfach & schnell zu deinem
        Führerschein.</h2>
      <div className="_6t _6s">
        <div className="_6y _6u">
          <div className="_6v" onClick={() => this.slideBy(-1)}>
            <ArrowLeft/>
          </div>
          <div className="_6w" onClick={() => this.slideBy(1)}>
            <ArrowRight/>
          </div>
        </div>
      </div>
      <div className="_13 _6t">
        <Slider slideStyle={circular} onMount={state => this.slideBy = state.slideBy}>

          <div className="_6x">

            <div className="_6y _6z">
              <h3 className={"_4d"}>Autoführerschein</h3>
              <h4 className="_4i">Klassen B, BE, B196 und B197</h4>

              <p><b>Manche Inhalte dieser Website sind schon unter 18 Jahren freigegeben.</b><br/><br/>
                Der Autoführerschein zum Beispiel. Sobald du über 16 ½ Jahre alt bist, kannst du bei uns
                zum begleiteten Fahren einsteigen.<br/><br/>Aber natürlich bringen wir auch alle anderen hinters
                Steuer – egal ob 20 oder 200 Jahre alt. Jetzt bei den Klassen BF17, B, BE, B196 und B197
                einsteigen und losfahren!</p>

              <Img alt={this.props.slides[0].imageAlt} fluid={this.props.slides[0].image.childImageSharp.fluid}/>

              <Link to="/auto/" className="_4t">Mehr Erfahren</Link>
            </div>

          </div>

          <div className="_6x">
            <div className="_6y">
              <h3 className="_4d">Zweiradklassen</h3>
              <h4 className="_4i">Klassen A1, A2, A, und AM</h4>
              <p><b>Manchen bringen zwei Räder mehr Spaß als vier. Dir auch?</b><br/><br/>
                Auch dann bist du bei uns genau richtig! Unsere Fahrlehrer*innen sind nicht nur selbst
                leidenschaftliche und geübte Rider, sondern auch bestens ausgebildet, dich für die Klassen
                A1, A2, A, oder AM fit zu machen.<br/><br/>
                Durch den Unterricht von Fahrlehrern, welche geschulte und geübte Motorradfahrer sind, sowie durch das
                Begleiten mit dem Motorrad während der praktischen Ausbildung, wird der größtmögliche Lerneffekt
                erzielt.
              </p>

              <Img alt={this.props.slides[1].imageAlt} fluid={this.props.slides[1].image.childImageSharp.fluid}/>

              <Link to="/motorrad/" className="_4t">Mehr Erfahren</Link>
            </div>
          </div>
          <div className="_6x">

            <div className="_6y">
              <h3 className={"_4d"}>ASF-Seminar</h3>

              <p><b>Doch mal in Flensburg gepunktet? Kein Problem.</b><br/><br/>Woran hat et jelegen? Ärgerlich – aber
                auch hier gibt’s eine Lösung. Im Aufbauseminar zur
                Nachschulung fragen wir uns das gemeinsam und arbeiten zusammen daran, dich wieder
                schnell und erfolgreich zurück auf die Straße zu bringen.</p>

              <Img alt={this.props.slides[2].imageAlt} fluid={this.props.slides[2].image.childImageSharp.fluid}/>

              <Link to="/asf-seminar/" className="_4t">Mehr Erfahren</Link>
            </div>

          </div>

        </Slider>
      </div>

    </section>
  }

}

ClassesSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ClassesSlider
