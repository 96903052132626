import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import ClassesSlider from "../components/classes-slider/classes-slider"
import HeroText from "../components/hero-text/hero-text"
import AnmeldungCtaSection from "../components/anmeldung-cta-section/anmeldung-cta-section"
import ContactAndAddressTile from "../components/contact-and-address-tile/contact-and-address-tile"
import About from "../components/about/about"
import ImageWithText from "../components/image-with-text/image-with-text"
import Accordion from "../components/accordion/accordion"
import Video from "../components/video/video"
import DigitalLicense from "../components/digital-license/digital-license"
import Manifesto from "../components/manifesto/manifesto"


const IndexPage = ({ data, location }) => {

  // Save Referrer to localstorage
  const params = new URLSearchParams(location.search);
  if (params.has('referrer')) {
    try {
      const referrer = atob(params.get('referrer') || '');
      localStorage.setItem('referrer', referrer);
    } catch (e) {}
  }

  const images = [
    {
      ...data.headerSmall.childImageSharp.fluid,
      media: "(max-width: 500px)",
    },
    {
      ...data.headerMedium.childImageSharp.fluid,
      media: "(max-width: 900px)",
    },
    {
      ...data.headerLarge.childImageSharp.fluid,
    },
  ]

  return <Layout>
    <SEO title={"Deine Fahrschule in Backnang"} description={'Deine Fahrschule in Backnang'}/>


    <Hero fluid={images}/>

    <HeroText></HeroText>


    <About></About>

    <Manifesto></Manifesto>

    <ClassesSlider slides={[
      {
        image: data.car,
        imageAlt: 'Autoführerschein'
      },
      {
        image: data.motorcycle,
        imageAlt: 'Motorradführerschein'
      },
      {
        image: data.asf,
        imageAlt: 'ASF-Seminar'
      },
    ]}></ClassesSlider>

    <AnmeldungCtaSection claimIndex={1}></AnmeldungCtaSection>
    <ContactAndAddressTile></ContactAndAddressTile>

    <Video></Video>

    <ImageWithText title={"Deine Lieblingsfahrschule in Backnang"} linkPath={"/anmeldung/"} linkText={"Melde dich jetzt an"}
                   body={() => <>Wir, das Team der Fahrschule Rauscher, haben es uns zur Aufgabe gemacht, dich auf deinem Weg zum Führerschein in Theorie und Praxis zu begleiten und dir die bestmögliche Fahrausbildung zu bieten. Schließlich toppt kaum etwas das Gefühl, nach frisch bestandener Prüfung hinter dem Lenkrad zu sitzen und maximale Freiheit zu genießen.<br/><br/>Und da du vermutlich nicht ohne Grund gerade nach Fahrschulen googelst: Besuch uns doch einfach mal (ganz legal zu Fuß) in der Aspacher Straße 53 in Backnang, schreib uns via WhatsApp unter <a href='tel:+491732717781' className={'_4n'}>0173 2717781</a> oder melde dich direkt auf unserer Website an.<br/><br/>Wir sind gerne die Beifahrer auf deinem Weg zur Fahrerlaubnis!</>}
                   imageAlt={"Fahrschule Rauscher — Deine Fahrschule aller Klassen in Backnang."}
                   imageFluid={data.fahrschuleBacknang.childImageSharp.fluid}></ImageWithText>
    <AnmeldungCtaSection claimIndex={0}></AnmeldungCtaSection>

    <DigitalLicense></DigitalLicense>


    <Accordion questionsAnswers={[
      {
        "question": "Wann kann ich mich zum Führerschein anmelden?",
        "answer": () => <p>Du kannst dich ein halbes Jahr vor deinem Geburtstag zum Führerschein anmelden. Die
          theoretische Prüfung kannst du frühestens 3 Monate vor deinem Geburtstag und die praktische frühestens 1 Monat
          vorher machen.</p>,
      },
      {
        "question": "Wie melde ich mich in der Fahrschule an?",
        "answer": () => <p>Die Anmeldung kannst du ganz bequem über unser Online Formular machen oder du kommst einfach
          persönlich zu unserer Bürozeiten vorbei.</p>,
      },
      {
        "question": "Was brauche ich für den Führerscheinantrag?",
        "answer": () => <p>Hierfür benötigst du einen aktuellen <b>Sehtest</b>, ein <b>biometrisches Passbild</b> und
          die
          Teilnahmebescheinigung an einem Kurs zu lebensrettenden Sofortmaßnahmen <b>(Erste-Hilfe-Kurs)</b>.</p>,
      }, {
        "question": "Wie lange dauert der Führerscheinantrag?",
        "answer": () => <p>Der Antrag dauert in der Regel 6-8 Wochen, im Sommer kann es aber auch länger dauern. Kümmere
          dich deswegen frühzeitig darum!</p>,
      }, {
        "question": "Was bedeutet Theorie in 7 Werktagen?",
        "answer": () => <p>Wir machen die Theorie ausschließlich in 'Intensivkursen' das bedeutet, dass wir jeden Tag
          zwei Einheiten abarbeiten.<br/><br/>Der Vorteil für dich besteht darin, dass du nicht über mehrere Wochen in
          die
          Fahrschule musst.</p>,
      }, {
        "question": "Was kostet der Führerschein?",
        "answer": () => <p>Hierzu schicken wir dir gerne eine ausführliche Preisinformation zu. In dieser findest du
          alle Posten aufgelistet.<br/><br/>Einen pauschalen Preis kann man leider nicht nennen, da man im Vorfeld nicht
          sagen
          kann wie viele Fahrstunden du benötigst.</p>,
      }, {
        "question": "Wie viele Fahrstunden brauche ich?",
        "answer": () => <p>Wie viele Fahrstunden du benötigst hängt von deinen Fähigkeiten und deinem Vorwissen ab.
          Unsere Ausbildung ist stets transparent, dass du immer weißt wo du gerade in der Ausbildung stehst und was
          noch auf dich zukommt.</p>,
      }, {
        "question": "Wie kann ich meinen Führerschein bezahlen?",
        "answer": () => <p>Hierzu gibt es vier Möglichkeiten.<br/><br/>1. Du zahlst per Rechnung (wir stellen diese auch
          gerne Online
          zu)<br/>2. Wir ziehen das Geld per SEPA Lastschrift Mandat ein.<br/>3. Du bezahlst deine Rechnung in Bar und
          bekommst anschließend eine Quittung<br/>4. Vorauszahlung</p>,
      }, {
        "question": "Wie kann ich mich auf die theoretische Prüfung vorbereiten?",
        "answer": () => <p>Hierzu bieten wir entweder eine App mit Buch oder nur eine App an, welche man bei uns
          käuflich erwerben kann.<br/><br/>Alle von uns zur Verfügung gestellten Lernmittel beinhalten die aktuellen
          amtlichen Prüffragen, welche auch an der Prüfung abgefragt werden.</p>,
      }, {
        "question": "Habe ich bei Anmeldung in einer Gruppe einen Vorteil?",
        "answer": () => <p>Ja bei Anmeldung in einer Gruppe gibt es in der Regel besondere Konditionen. Dies ist
          übrigens auch der Fall wenn du uns weiterempfiehlst.</p>,
      }
    ]}></Accordion>

  </Layout>
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    headerSmall: file(relativePath: { eq: "hero_small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerMedium: file(relativePath: { eq: "hero_medium.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerLarge: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
    car: file(relativePath: { eq: "klasse-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      asf: file(relativePath: { eq: "asf-seminar.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    motorcycle: file(relativePath: { eq: "klasse-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fahrschuleBacknang: file(relativePath: { eq: "fahrschule-backnang.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
