import React from "react"

import "./manifesto.sass"

const Manifesto = () => {

  return <section className="_8j">

    <div className="_13">
      <div className="_16">
        <div className={"_8l"}>

          <h3>

            Die vermutlich sicherste Fahrschule Deutschlands – das ist unser Anspruch.
          </h3>
          <br/>
          <h6>
            Sicherheit steht bei uns an erster Stelle!
          </h6>
          <p>
            Bei der Fahrschule Rauscher legen wir größten Wert darauf, unseren Schülern nicht nur das Fahren, sondern auch ein sicheres und verantwortungsbewusstes Verhalten im Straßenverkehr zu vermitteln. Unser Ziel ist es, dass du nach deiner Ausbildung nicht nur die Fahrprüfung bestehst, sondern dich in jeder Situation sicher fühlst – egal, ob bei Tageslicht, Regen oder auf glatten Straßen. Denn deine Sicherheit ist nicht nur in deinem Interesse, sondern auch im Interesse deiner Lieben, wie Eltern, Familie, Freunde und Bekannten, deshalb geben wir Dir die Fahrsicherheit und somit allen ein gutes und sicheres Gefühl.
          </p>
          <br></br>
          <h6>

            Unsere Prinzipien für deine Sicherheit
          </h6>
          <ul>
            <li><strong>Individuelle Betreuung:</strong> Wir nehmen uns Zeit für dich, um sicherzustellen, dass du alle nötigen
              Fähigkeiten und Kenntnisse erlernst.
            </li>
            <li><strong>Moderne Ausbildungsmethoden:</strong> Wir nutzen neueste Technologien und moderne Fahrzeuge, um dir eine
              erstklassige und sichere Ausbildung zu bieten.
            </li>
            <li><strong>Sicherheitsorientierte Fahrpraxis:</strong> Mit uns lernst du nicht nur fahren, sondern auch, wie du gefährliche
              Situationen vermeidest und stets die Kontrolle behältst.
            </li>
            <li><strong>Qualifizierte Fahrlehrer:</strong> Unser Team besteht aus erfahrenen Fahrlehrern, die sich regelmäßig
              weiterbilden, um dir die bestmögliche Ausbildung zu gewährleisten.
            </li>
          </ul>


          <br/>
          <h6>Unser Versprechen</h6>

          <p>Wir bereiten dich optimal auf den Straßenverkehr vor und geben dir das Wissen und die Fähigkeiten an die
            Hand, um sicher und souverän unterwegs zu sein – heute und in Zukunft. Werde Teil der Fahrschule Rauscher
            und erlebe eine Ausbildung, die höchste Sicherheitsstandards setzt.</p>


        </div>
      </div>
    </div>
    <div className="_8k"/>

  </section>
}

export default Manifesto
