import React from "react"
import PropTypes from 'prop-types'
import * as moment from 'moment'

import "./intensivkurs-termin.sass"

const IntensivkursTermin = ({termin}) => {
  return <div className={'_95'}>
    <p className={'_96 _4j _4k'}>Startet am</p>
    <h4 className={'_97 _4e'}>{moment(termin.date).format('DD.MM.YYYY')}</h4>
    {/*<div className={'_98'}>*/}

    {/*<p className={'_4j'}>Mo-Fr:</p><p className={'_4j'}>18:15 Uhr – 21:30 Uhr</p>*/}
    {/*<p className={'_4j'}>Sa:</p><p className={'_4j'}>09:00 Uhr – 12:15 Uhr</p>*/}
    {/*</div>*/}
    <div className={'_99'}><a href={'/anmeldung/'} className={'_4t'}>Jetzt Anmelden</a></div>
  </div>
}

IntensivkursTermin.propTypes = {
  termin: PropTypes.any
}

export default IntensivkursTermin
